import React, { Component } from "react";
import "./Quote.css";

type QuoteProps = {
  quote: string;
  atribution: React.ReactNode;
};

export class Quote extends Component<QuoteProps> {
  render() {
    return (
      <div className="w3-container w3-row w3-center w3-green w3-padding-64">
        <h3>{this.props.quote}</h3>
        {this.props.atribution}
      </div>
    );
  }
}
