import React, { Component } from "react";
import "./PolicySection.css";

type PolicySectionProps = {
  sectionTitle: String;
  sectionContent: React.ReactNode;
};

export class PolicySection extends Component<PolicySectionProps> {
  render() {
    return (
      <div className="w3-container w3-light-gray splitContainer">
        <div className="w3-row-padding">
          <div className="w3-col m6">
            <h3>{this.props.sectionTitle}</h3>
          </div>
          <div className="w3-col m6">{this.props.sectionContent}</div>
        </div>
      </div>
    );
  }
}
