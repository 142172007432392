import React, { Component } from "react";
import "./Nav.css";
const links = require("../../config/links.json");

export class Nav extends Component {
  render() {
    function openSideBar() {
      var mySidebar = document.getElementById("mySidebar");
      if (mySidebar != null) {
        if (mySidebar.style.display === "block") {
          mySidebar.style.display = "none";
        } else {
          mySidebar.style.display = "block";
        }
      }
    }

    function closeSideBar() {
      var mySidebar = document.getElementById("mySidebar");
      if (mySidebar != null) {
        mySidebar.style.display = "none";
      }
    }

    return (
      <div>
        <div className="w3-top">
          <div className="w3-bar w3-white w3-card" id="myNavbar">
            <a href="/" className="w3-bar-item w3-button flightDeckLogoFont">
              <img className="w3-image navLogo" src="assets/logo.png" />
              FlightDeck EFB
            </a>

            <div className="w3-right w3-hide-small">
              <a href="/" className="w3-bar-item w3-button">
                HOME
              </a>
              <a href="/download" className="w3-bar-item w3-button">
                DOWNLOAD
              </a>
              <a href="/contact" className="w3-bar-item w3-button">
                CONTACT
              </a>
            </div>

            <a
              href="javascript:void(0)"
              className="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium"
              onClick={openSideBar}
            >
              <i className="fa fa-bars"></i>
            </a>
          </div>
        </div>
        <nav
          className="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large navSideBar"
          id="mySidebar"
        >
          <a
            href="javascript:void(0)"
            onClick={closeSideBar}
            className="w3-bar-item w3-button w3-large w3-padding-16"
          >
            Close ×
          </a>
          <a href="/" onClick={closeSideBar} className="w3-bar-item w3-button">
            HOME
          </a>
          <a
            href="/download"
            onClick={closeSideBar}
            className="w3-bar-item w3-button"
          >
            DOWNLOAD
          </a>
          <a
            href="/contact"
            onClick={closeSideBar}
            className="w3-bar-item w3-button"
          >
            CONTACT
          </a>
        </nav>
      </div>
    );
  }
}
