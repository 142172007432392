import React, { Component } from "react";
import "./About.css";
const links = require("../../config/links.json");

export class About extends Component {
  render() {
    return (
      <div className="w3-container w3-light-grey aboutContainer" id="about">
        <p className="w3-center w3-large">
          We make the best Electronic Pilot Kneeboard application in the world.
          It is designed to simplify your processes and reduce pilot workload
          leading to safer flights and happier pilots!
        </p>
        <div className="w3-row-padding w3-center aboutTopMargin">
          <div className="w3-quarter">
            <i className="fa fa-desktop w3-margin-bottom w3-jumbo w3-center"></i>
            <p className="w3-large">Focused & Carefully Designed</p>
            <p>
              FlightDeck EFB is not designed to replicate your full FlightBag.
              It is designed great at what it does and be used in conjunction
              with other EFB Applications like ForeFlight, Skydemon and others!
            </p>
          </div>
          <div className="w3-quarter">
            <i className="fa fa-heart w3-margin-bottom w3-jumbo"></i>
            <p className="w3-large">Fair</p>
            <p>
              We believe great tools should be accessible by pilots of all
              kinds. As such FlightDeck EFBs core features will always be
              available free of charge and any features we charge for in future
              will be low cost.
            </p>
          </div>
          <div className="w3-quarter">
            <i className="fa fa-gem w3-margin-bottom w3-jumbo"></i>
            <p className="w3-large">Community Driven</p>
            <p>
              The app is used in all kinds of aircraft from Paragliders to 737s
              and its our community of pilots that are really helping to drive
              the product forward! If you want to help us build the next great
              feature (or report an issue) join our Discord Community!
            </p>
          </div>
          <div className="w3-quarter">
            <i className="fa fa-cog w3-margin-bottom w3-jumbo"></i>
            <p className="w3-large">Transparent</p>
            <p>
              Our customers are not our product. We don't collect data we dont
              need. We don't sell your data. We dont show adverts. You can ask
              us to delete any data at anytime. Our policies are fair, clear and
              available for all to see here:{" "}
              <a href="/policies">Our Policies</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
