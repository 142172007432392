import React, { Component } from "react";
import "./Title.css";

type ParagraphProps = {
  text: React.ReactNode;
};

export class Paragraph extends Component<ParagraphProps> {
  render() {
    return (
      <div className="w3-container w3-row w3-center w3-dark-gray w3-padding-64">
        {this.props.text}
      </div>
    );
  }
}
