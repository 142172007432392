import React, { Component } from "react";
import "./Footer.css";

export class Footer extends Component {
  render() {
    return (
      <footer className="w3-center w3-dark-gray w3-padding-64">
        <a href="#home" className="w3-button w3-light-grey">
          <i className="fa fa-arrow-up w3-margin-right"></i>To the top
        </a>
        <div className="w3-xlarge w3-section w3-wide">
          <i className="fab fa-instagram w3-hover-opacity"></i>
          <i className="fab fa-discord w3-hover-opacity"></i>
        </div>
        <p>FlightDeck EFB &copy; Carl Robinson 2022. All rights reserved.</p>
      </footer>
    );
  }
}
