import React from "react";
import "./App.css";
import { HomePage } from "./pages/home/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Blank } from "./components/blank/Blank";
import Layout from "./pages/layout/Layout";
import { PoliciesPage } from "./pages/policies/PoliciesPage";
import { ContactPage } from "./pages/contact/ContactPage";
import { DownloadPage } from "./pages/download/DownloadPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="policies" element={<PoliciesPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="download" element={<DownloadPage />} />
          <Route path="*" element={<Blank />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
