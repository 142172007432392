import React, { Component } from "react";
import "./ContactPage.css";
import { Footer } from "../../components/footer/Footer";
import { Title } from "../../components/text/Title";
import { Contact } from "../../components/contact/Contact";

export class ContactPage extends Component {
  render() {
    return (
      <div className="contactPage">
        <div className="container">
          <Title title={<h3>Contact Us</h3>} />
          <Contact />
          <Footer />
        </div>
      </div>
    );
  }
}
