import React, { Component } from "react";
import "./DownloadPage.css";
import { Footer } from "../../components/footer/Footer";
import { Title } from "../../components/text/Title";
import { Features } from "../../components/features/Features";
import { PricingOne } from "../../components/pricing/PricingOne";
import { Paragraph } from "../../components/text/Paragraph";

export class DownloadPage extends Component {
  render() {
    return (
      <div className="pricingPage">
        <div className="container">
          <Title
            title={
              <h3>
                Download{" "}
                <span className="flightDeckLogoFont">FlightDeck EFB</span>
              </h3>
            }
          />
          <Paragraph
            text={
              <p>
                Built by pilots for pilots,{" "}
                <span className="flightDeckLogoFont">FlightDeck EFB</span>{" "}
                delivers a digital cockpit management solution covering
                checklists, scratchpads, FlightLogs and much more, in order to
                simplify processes and increase efficiency. In turn this should
                reduce pilot workload making aviation safer and less intense for
                pilots.
              </p>
            }
          />
          <Features />
          <Paragraph
            text={
              <p>
                Aviation safety is something we care greatly about. As such we
                dont see the bennefit of creating amazing tools that can only be
                used by those in a position to stump up large ammounts of money
                anually. In an effort to make safe aviation accessible for every
                pilot FlightDeck EFBs core features will always be available
                free of charge. In addition any subscriptions or chargeable
                features we introduce in future will be optional and low price.
              </p>
            }
          />
          <PricingOne />
          <Footer />
        </div>
      </div>
    );
  }
}
