import React, { Component } from "react";
import "./Title.css";

type TitleProps = {
  title: React.ReactNode;
};

export class Title extends Component<TitleProps> {
  render() {
    return (
      <div className="w3-container w3-row w3-center w3-blue w3-padding-64">
        {this.props.title}
      </div>
    );
  }
}
