import React, { Component } from "react";
import "./Contact.css";
const links = require("../../config/links.json");

export class Contact extends Component {
  render() {
    return (
      <div className="w3-container w3-light-grey contactContainer" id="contact">
        <p className="w3-center w3-large">
          Lets get in touch. Send us a message via the following channels:
        </p>
        <div className="contactTopMargin"></div>

        <div className="w3-row-padding w3-grayscale contactTopMargin">
          <div className="w3-col s12 m6 l6 w3-margin-bottom">
            <a href={links.community.discord} className="contactCardLink">
              <div className="w3-card contactCardHeight">
                <div className="w3-container">
                  <h3>FlightDeck Community Discord</h3>
                  <p>
                    Tap this card to join the join our community discord. Here
                    you can submit ideas, get help or even just discuss aviation
                    with other users!
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="w3-col s12 m6 l6 w3-margin-bottom">
            <div className="w3-card contactCardHeight">
              <div className="w3-container">
                <h3>FlightDeck Email</h3>
                <p>
                  Prefer email? No worries simply send us a message at
                  support@flightdeckefb.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
