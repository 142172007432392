import React, { Component } from "react";
import "./PoliciesPage.css";
import { Footer } from "../../components/footer/Footer";
import { Title } from "../../components/text/Title";
import { PolicySection } from "../../components/policy_section/PolicySection";

export class PoliciesPage extends Component {
  render() {
    return (
      <div className="policiesPage">
        <div className="container">
          <Title title={<h3>Our Policies</h3>} />
          <PolicySection
            sectionTitle="Introduction"
            sectionContent={
              <div>
                <p>
                  Welcome to the policies section of the{" "}
                  <span className="flightDeckLogoFont">FlightDeck EFB</span>{" "}
                  website. Below you will find an easy to read but accurate
                  explanation of the data we collect, what we do with it and a
                  number of promises to you as users, supporters and customers.
                </p>
              </div>
            }
          />

          <PolicySection
            sectionTitle="Privacy Policy"
            sectionContent={
              <div>
                <p>
                  The <span className="flightDeckLogoFont">FlightDeck EFB</span>
                  does not collect or transmit any personal data for the purpose
                  of revenue generation by selling that data.
                </p>
                <p>Feature Data:</p>
                <p>
                  Feature data provided by the user in the form of checklists,
                  notes, flight logs or settings are stored on device. This
                  Feature Data will only be shared or uploaded to our servers if
                  requested by the user through our export, sharing or cloud
                  handoff functionality.
                </p>
                <p>Personal Data:</p>
                <p>
                  Some future functionality may require the user to register for
                  a "FlightDeck Account". Whilst this is not currently required
                  for any functionality we wish to layout our plans for personal
                  user data collected when creating an account. We will only
                  ever request information from the user that is required in
                  order for us to provide a service and where possible we wont
                  collect any personal data at all. At any point you may request
                  that we delete any data we hold on you via the email on the
                  contact page.
                </p>

                <p>Other Data:</p>
                <p>
                  We reserve the right to collect completely anonymous useage
                  data from our apps and website. This is for analytics purposes
                  and to allow us to see which features are or aren't being
                  used. The data holds no link to your user profile or any
                  personal data we may hold.
                </p>
              </div>
            }
          />

          <PolicySection
            sectionTitle="Advertising Policy"
            sectionContent={
              <div>
                <p>
                  It is our commitment that we will not display adverts from "Ad
                  Networks" in the{" "}
                  <span className="flightDeckLogoFont">FlightDeck EFB</span>{" "}
                  Mobile or Web Apps. This is a simple, solid commitment that we
                  make not only to protect customer data and privacy but also to
                  remove distractions in the cockpit.
                </p>
                <p>
                  We may from time to time place "featured content" such as
                  Managed Checklists, Links and Media in the community section
                  of our apps from users or partners. This will be the only area
                  of the system where promoted content will appear as to not
                  interfere with pilot duties.
                </p>
              </div>
            }
          />
          <Footer />
        </div>
      </div>
    );
  }
}
