import React, { Component } from "react";
import "./Features.css";

export class Features extends Component {
  render() {
    return (
      <div className="w3-container w3-padding-64 w3-light-grey w3-center">
        <h3>
          Why choose <span className="flightDeckLogoFont">FlightDeck EFB</span>
        </h3>
        <p>Great features, Free Forever</p>

        <div className="w3-row featureRow">
          <div className="w3-col s3">
            <i className="fa fa-list w3-text-green w3-jumbo"></i>
            <p>Checklists</p>
          </div>
          <div className="w3-col s3">
            <i className="fa fa-pen w3-text-blue w3-jumbo"></i>
            <p>ScratchPad</p>
          </div>
          <div className="w3-col s3">
            <i className="fa fa-book w3-text-orange w3-jumbo"></i>
            <p>FlightLogs</p>
          </div>
          <div className="w3-col s3">
            <i className="fa fa-location-arrow w3-text-red w3-jumbo"></i>
            <p>FlightAssist</p>
          </div>
        </div>

        <div className="w3-row featureRow">
          <div className="w3-col s3">
            <i className="fa fa-clock w3-text-orange w3-jumbo"></i>
            <p>Reminders</p>
          </div>
          <div className="w3-col s3">
            <i className="fa fa-file w3-text-green w3-jumbo"></i>
            <p>Documents</p>
          </div>
          <div className="w3-col s3">
            <i className="fa fa-users w3-text-blue w3-jumbo"></i>
            <p>Community</p>
          </div>
          <div className="w3-col s3">
            <i className="fa fa-moon w3-text-dark-grey w3-jumbo"></i>
            <p>Dark Mode</p>
          </div>
        </div>
      </div>
    );
  }
}
