import React, { Component } from "react";
import "./Community.css";
const links = require("../../config/links.json");

export class Community extends Component {
  render() {
    return (
      <div
        className="w3-container w3-light-grey communityContainer"
        id="community"
      >
        <h3 className="w3-center">Community</h3>
        <p className="w3-center w3-large">
          Become part of a great Aviation Community
        </p>

        <div className="w3-row-padding w3-grayscale communityTopMargin">
          <div className="w3-col s12 m6 l6 w3-margin-bottom">
            <a href={links.community.discord} className="cardLink">
              <div className="w3-card cardHeight">
                <img
                  src="assets/discord.png"
                  alt="Discord"
                  className="communityImageWidth"
                />
                <div className="w3-container">
                  <h3>FlightDeck Community Discord</h3>
                  <p>
                    Our community discord where the community talk FlightDeck,
                    Aviation and almost anything else!
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div className="w3-col s12 m6 l6 w3-margin-bottom">
            <a href={links.community.instagram} className="cardLink">
              <div className="w3-card cardHeight">
                <img
                  src="assets/insta.png"
                  alt="Instagram"
                  className="communityImageWidth"
                />
                <div className="w3-container">
                  <h3>@flightdeckefb on Instagram</h3>
                  <p>
                    Our Instagram feed! We post about any big updates and
                    collaberate with our community here!
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

{
  /* <div className="w3-col l3 m6 w3-margin-bottom communityItem">
            <a href={links.community.discord} className="cardLink">
              <div className="w3-card cardHeight">
                <img
                  src="assets/cavok.jpeg"
                  alt="CAVOK Aviation"
                  className="communityImageWidth"
                />
                <div className="w3-container">
                  <h3>CAVOK Aviation</h3>
                  <p>
                    If you are looking for hour building oppertunities in the UK
                    look no further than CAVOK Aviation!
                  </p>
                </div>
              </div>
            </a>
          </div> */
}
