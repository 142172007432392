import React, { Component } from "react";
import "./Hero.css";
const links = require("../../config/links.json");

export class Hero extends Component {
  render() {
    return (
      <div className="w3-container w3-dark-grey hero-background-image">
        <div className="w3-row-padding">
          <div className="w3-col m6 w3-padding-64 w3-hide-small">
            <h3>
              <span className="w3-xxlarge">
                Meet <span className="flightDeckLogoFont">FlightDeck EFB</span>.
              </span>
            </h3>
            <p className="w3-large">
              The Electronic Kneeboard designed for every pilot.
            </p>
            <p>
              <a
                href={links.download.appStore}
                className="w3-button w3-dark-grey w3-padding-large w3-large w3-margin-top"
              >
                Download from the Apple AppStore
              </a>
            </p>
          </div>
          <div className="w3-col m6 w3-padding-64 w3-center w3-hide-large w3-hide-medium">
            <h3>
              <span className="w3-xxlarge">
                Meet <span className="flightDeckLogoFont">FlightDeck EFB</span>.
              </span>
            </h3>
            <p className="w3-large">
              The Electronic Kneeboard designed for every pilot.
            </p>
            <p>
              <a
                href={links.download.appStore}
                className="w3-button w3-dark-grey w3-padding-large w3-large w3-margin-top"
              >
                Download from the Apple AppStore
              </a>
            </p>
          </div>
          <div className="w3-col m6">
            <img
              className="w3-image hero-device-image "
              src="assets/hero-screen.png"
              alt="iPad running flightdeck efb"
              width="425"
              height="550"
            />
          </div>
        </div>
      </div>
    );
  }
}
