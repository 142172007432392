import React, { Component } from "react";
import "./HomePage.css";
import { About } from "../../components/about/About";
import { Community } from "../../components/community/Community";
import { Footer } from "../../components/footer/Footer";
import { Quote } from "../../components/quote/Quote";
import { Split } from "../../components/split/Split";
import { Statistics } from "../../components/statistics/Statistics";
import { Title } from "../../components/text/Title";
import { Features } from "../../components/features/Features";
import { Hero } from "../../components/hero/Hero";

export class HomePage extends Component {
  render() {
    return (
      <div className="homePage">
        <div className="container">
          <Hero />
          <Title
            title={
              <h3>
                Welcome to{" "}
                <span className="flightDeckLogoFont">FlightDeck EFB</span>
              </h3>
            }
          />
          <About />
          <Statistics />
          <Features />
          <Quote
            quote="We are on a mission to simplify cockpit processes to reduce pilot
          workload and improve safety"
            atribution={
              <p className="w3-large">
                Carl Robinson, Founder
                <span className="flightDeckLogoFont"> FlightDeck EFB</span>
              </p>
            }
          />
          <Community />
          <Split />
          <Footer />
        </div>
      </div>
    );
  }
}
