import React, { Component } from "react";
import "./Statistics.css";

export class Statistics extends Component {
  render() {
    return (
      <div className="w3-container w3-row w3-center w3-orange w3-padding-64">
        <div className="w3-third">
          <span className="w3-xxlarge">2000+</span>
          <br />
          Users
        </div>
        <div className="w3-third">
          <span className="w3-xxlarge">4.9</span>
          <br />
          On the App Store
        </div>
        <div className="w3-third">
          <span className="w3-xxlarge">1</span>
          <br />
          Amazing Community
        </div>
      </div>
    );
  }
}
