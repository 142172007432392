import React, { Component } from "react";
import "./Split.css";

export class Split extends Component {
  render() {
    return (
      <div className="w3-container w3-blue splitContainer">
        <div className="w3-row-padding">
          <div className="w3-col m6">
            <h3>
              <span className="flightDeckLogoFont">FlightDeck EFB</span> x Your
              Favourite Aviation Apps
            </h3>
            <p>
              <span className="flightDeckLogoFont">FlightDeck EFB</span> on iPad
              is designed to work side by side with all of your favourite
              aviation apps including SkyDemon, ForeFlight and many others!
            </p>
          </div>
          <div className="w3-col m6">
            <img
              className="w3-image w3-round-large"
              src="assets/split.png"
              alt="Buildings"
              width="500"
            />
          </div>
        </div>
      </div>
    );
  }
}
