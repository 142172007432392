import React, { Component } from "react";
import "./PricingOne.css";
const links = require("../../config/links.json");

export class PricingOne extends Component {
  render() {
    return (
      <div
        className="w3-container w3-center w3-light-grey pricingContainer"
        id="pricing"
      >
        <p className="w3-large">
          <span className="flightDeckLogoFont">FlightDeck EFB</span> is the EFB
          app for all pilots!
        </p>
        <div className="w3-row-padding pricingTopMargin">
          <div className="w3-full w3-section">
            <ul className="w3-ul w3-white w3-hover-shadow pricing-card-shadow">
              <li className="w3-black w3-xlarge w3-padding-32 flightDeckLogoFont">
                FlightDeck Core
              </li>
              <li className="w3-padding-16">
                <p>
                  <b>Checklists</b> - Our industry leading checklist
                  functionality with the ability to create your own checklists,
                  user one of our templates or use a "Managed Checklist" from
                  one of our partners
                </p>
              </li>
              <li className="w3-padding-16">
                <p>
                  <b>ScratchPad</b> - Write down those frequencies and
                  clearances with our simple & intuitive notepad. It even
                  supports Apple Pencil!
                </p>
              </li>
              <li className="w3-padding-16">
                <p>
                  <b>FlightLogs</b> - Quickly capture flight details using our
                  simple FlightLogs functionality reducing your workload in the
                  cockpit. Quickly record times with a tap & note down other
                  vital information making it easy to transfer to your log book
                  later!
                </p>
              </li>
              <li className="w3-padding-16">
                <p>
                  <b>FlightAssist</b> - Device derived Ground Speed, Heading and
                  height information.
                </p>
              </li>
              <li className="w3-padding-16">
                <p>
                  <b>Reminders</b> - Never forget a key date again! Setup
                  reminders for Maintainance, medical and license expiry to be
                  notified when you open the app!
                </p>
              </li>
              <li className="w3-padding-16">
                <h2 className="w3-wide">FREE</h2>
                <span className="w3-opacity">forever</span>
              </li>
              <li className="w3-light-grey w3-padding-24">
                <a
                  href={links.download.appStore}
                  className="w3-button w3-blue w3-padding-large w3-large w3-margin-top"
                >
                  Download from the Apple AppStore
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
